<template>
  <div>
  
    <div>
      <b-tabs  pills>
      <!-- Tab: Account -->
      <b-tab active  @RefershGrid="getPatientPendingList"
              @click="IsAppointment==true;">
        <template #title>
          <feather-icon icon="CornerDownRightIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('AppointmentPendingPayments') + '  (' + AppointmentCount + ')'}}</span>
        </template>
        <Patient-Payment-Pending class="mt-4"  :Model="AppointsModel"  :IsAppointment="IsAppointment" />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab  @RefershGrid="getPatientPendingList"
               @click="IsAppointment==false;">
        <template #title>
          <feather-icon icon="CornerDownLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('SurgeriesPendingPayments') + '  (' + SurgeriesCount + ')'}}</span>
        </template>
        <Patient-Payment-Pending class="mt-4"  :Model="SurgeriesModel" />
      </b-tab>

    </b-tabs>
     
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";

import { domain } from "@/gloabelConstant.js";
import PatientPaymentPending from "@/views/Patient/Payment/PatientPaymentPending.vue";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";

export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
      AppointsModel: [],
      SurgeriesModel: [],

      activeTab: 0,
      EnglishLan: false,
      SurgeriesCount: 0,
      AppointmentCount: 0,
      IsAppointment:true,

    };
  },
  components: {
    PatientPaymentPending,
    BAlert, BButton, BCard, BLink, BTab, BTabs
  },
  props: {

  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getPatientPendingList() {
       this.$store
      .dispatch(
        "patientList/getPatientPendingList",
        this.$store.state.AppActiveUser.Patient.ID
      )
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
          this.AppointmentCount=this.Model.filter(x=>x.PatientReservedSessionID!=undefined || x.PatientReservedSessionID>0 ).length;
          this.SurgeriesCount=this.Model.filter(x=>x.PatientReservedSurgeryID!=undefined || x.PatientReservedSurgeryID>0 ).length;
          this.$vs.loading.close();
            if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        else
        {
        this.SurgeriesModel=this.Model.filter(x=>x.PatientReservedSurgeryID>0);
        this.AppointsModel=this.Model.filter(x=>x.PatientReservedSessionID>0);
        }
        }
      })
      .catch(() => {
        window.showError();
      });
    },
     PayNow(data) {
        if(data.PatientReservedSessionID)
        {
          var reservation={"ID":data.PatientReservedSessionID};
          reservation.FrontUrl = window.location.origin

          this.$store
            .dispatch("patientList/AppointmentReservationPaymentDatail", reservation)
            .then((res) => {
              debugger;
                window.location.href  = res.data.Data.transaction.url;

              // document.getElementById("amount").value = res.data.Data.amount;
              // document.getElementById("customer_email").value =
              //   res.data.Data.customer_email;
              // document.getElementById("signature").value = res.data.Data.signature;
              // document.getElementById("merchant_reference").value =
              //   res.data.Data.merchant_reference;
              //   this.$emit("closePop");
              // document.getElementById("paymentForm").submit();
            });
        }
        else
        {
            this.$router.push("/Patient/Surgeryprofile/"+data.PatientReservedSurgeryID)
        }
    },


  },
  created() {
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    this.getPatientPendingList();

  },
};
</script>
